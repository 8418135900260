import React, { useEffect, useContext } from "react";
import CookieConsent from "react-cookie-consent";
import GlobalContext, { themeConfigDefault } from "../../context/GlobalContext";

const PageWrapper = ({ children, themeConfig = null }) => {
  const gContext = useContext(GlobalContext);

  useEffect(() => {
    gContext.changeTheme({ ...themeConfigDefault, ...themeConfig });
  }, []);

  return (
    <>
      {children}
      <CookieConsent
        buttonText="Entendido"
        style={{ background: "#E4E8F5", color: "#52555F" }}
        buttonStyle={{
          background: "#0f53fa",
          color: "#fff",
          borderRadius: 4,
          fontSize: "13px",
        }}
      >
        Esta página utiliza cookies de terceros para protegerse de spam y
        fraude. Para más información consulte nuestra{" "}
        <a href="/privacy">política de privacidad</a>.
      </CookieConsent>
    </>
  );
};

export default PageWrapper;
